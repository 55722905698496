'use client';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  StackDivider,
  VStack,
  extendTheme,
  useDisclosure,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import { ComponentProps, Suspense, useEffect, useRef } from 'react';
import {
  FaChartLine,
  FaDiscord,
  FaHome,
  FaNpm,
  FaPaypal,
  FaQuestion,
  FaReddit,
  FaTable,
} from 'react-icons/fa';
import { FaRankingStar } from 'react-icons/fa6';
import { IconType } from 'react-icons/lib';
import { config } from '../lib/@chakra-ui/theme';
import { useFocusPlayer } from '../lib/hooks/focus-player';
import { useNavigationStartSignal } from '../lib/hooks/navigation-start-event';
import CircleIcon from './circle-icon';
import { VerticalCenter } from './vertical-center';
import Testing from './testing';

export const theme = extendTheme({ config });
const NavigationItem = ({
  isSelected,
  href,
  label,
  icon,
}: {
  isSelected: boolean;
  href: string;
  label: string;
  icon: IconType;
}) => (
  <Box>
    <Link as={NextLink} href={href}>
      <Icon as={icon} mr={2} boxSize={3} />
      {label}
      {isSelected ? <CircleIcon boxSize={2} marginLeft={2} /> : null}
    </Link>
  </Box>
);

export function NavigationMenu(props: ComponentProps<typeof Flex>) {
  const pathname = usePathname();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  const { focusPlayer } = useFocusPlayer();
  return (
    <>
      <Suspense>
        <NavStartClose onClose={onClose} />
      </Suspense>
      <VerticalCenter {...props}>
        <IconButton
          aria-label="Menu"
          icon={<HamburgerIcon boxSize={6} />}
          ref={btnRef}
          onClick={onOpen}
          variant={'ghost'}
        />
      </VerticalCenter>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton top={4} variant="ghost" />
          <DrawerHeader>
            <Link as={NextLink} href="/">
              <VerticalCenter>
                <Image
                  src="/Horizontal_Logo_Cyan.svg"
                  alt="Title Logo"
                  width="240px"
                />
              </VerticalCenter>
            </Link>
          </DrawerHeader>
          <DrawerBody>
            <VStack
              divider={<StackDivider borderColor="gray.500" />}
              spacing={2}
              align="stretch"
            >
              <NavigationItem
                isSelected={pathname === '/'}
                href="/"
                label="Home"
                icon={FaHome}
              />
              {focusPlayer && (
                <NavigationItem
                  isSelected={pathname.startsWith('/matches')}
                  href={'/matches?gamertag=' + focusPlayer}
                  label="Matches"
                  icon={FaTable}
                />
              )}
              {focusPlayer && (
                <NavigationItem
                  isSelected={pathname.startsWith('/players')}
                  href={'/players/' + focusPlayer}
                  label="Profile"
                  icon={FaChartLine}
                />
              )}
              <NavigationItem
                isSelected={pathname === '/leaderboard'}
                href="/leaderboard"
                label="Leaderboard"
                icon={FaRankingStar}
              />
              <NavigationItem
                isSelected={pathname === '/faqs'}
                href="/faqs"
                label="FAQs"
                icon={FaQuestion}
              />
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <Flex width="100%" direction="column" gap={4}>
              <Center width="100%">
                <Link
                  as={NextLink}
                  href="https://www.paypal.com/donate/?hosted_button_id=4KNG4S2NTJGRA"
                  prefetch={false}
                  isExternal={true}
                >
                  <Button
                    borderRadius={50}
                    height={6}
                    colorScheme="yellow"
                    leftIcon={<FaPaypal />}
                  >
                    Buy GravlLift a Beer
                  </Button>
                </Link>
              </Center>
              <Flex width="100%" justifyContent="space-evenly">
                <Box>
                  <Link
                    isExternal
                    href="https://www.reddit.com/user/HaloQuery"
                    title="Halo Query on Reddit"
                  >
                    <Icon as={FaReddit} boxSize={8} />
                  </Link>
                </Box>
                <Box>
                  <Link
                    isExternal
                    href="https://discord.gg/XuZsWZ2h5p"
                    title="Halo Query on Discord"
                  >
                    <Icon as={FaDiscord} boxSize={8} />
                  </Link>
                </Box>
                <Box>
                  <Link
                    isExternal
                    href="https://www.npmjs.com/package/halo-infinite-api"
                    title="Powered by halo-infinite-api"
                  >
                    <Icon as={FaNpm} boxSize={8} />
                  </Link>
                </Box>
              </Flex>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

function NavStartClose({ onClose }: { onClose: () => void }) {
  const navStartSignal = useNavigationStartSignal();
  useEffect(() => {
    navStartSignal.addEventListener('abort', onClose);
    return () => {
      navStartSignal.removeEventListener('abort', onClose);
    };
  }, [navStartSignal, onClose]);
  return <></>;
}

'use client';
import type { UserInfo, XboxTicket } from 'halo-infinite-api';
import { RelyingParty } from 'halo-infinite-api';
import { useReadLocalStorage } from '../hooks/local-storage';
import { useEffect, useState } from 'react';
import { usersCache } from '../caches';
import { initialize } from '../msal-instances/client';

export function useCurrentUserGamertag() {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    initialize().then(() => setInitialized(true));
  }, [setInitialized]);

  const xboxXstsTicket = useReadLocalStorage<XboxTicket>(
    'xbox.xstsTicket.' + RelyingParty.Xbox
  );
  if (initialized && xboxXstsTicket) {
    return xboxXstsTicket.DisplayClaims.xui[0].gtg as string;
  }
  return null;
}

export function useCurrentUser() {
  const [user, setUser] = useState<UserInfo>();

  const gamertag = useCurrentUserGamertag();
  useEffect(() => {
    if (gamertag) {
      usersCache
        .get(gamertag)
        .then((userInfo) => setUser(userInfo))
        .catch((e) => console.error(e));
    }
  }, [gamertag]);

  return user;
}

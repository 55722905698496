'use client';
import { CacheProvider } from '@chakra-ui/next-js';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { config } from '../lib/@chakra-ui/theme';
import LeaderboardProvider from '../components/leaderboard-provider';

const theme = extendTheme({
  config,
  radii: {
    none: '0',
    sm: '0',
    base: '0',
    md: '0',
    lg: '0',
    xl: '0',
    '2xl': '0',
    '3xl': '0',
  },
});

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <LeaderboardProvider>
      <CacheProvider>
        <ChakraProvider theme={theme}>{children}</ChakraProvider>
      </CacheProvider>
    </LeaderboardProvider>
  );
}

'use client';
import {
  ClientAuthError,
  ClientAuthErrorCodes,
  ServerError,
} from '@azure/msal-node';
import {
  Avatar,
  Box,
  Card,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  forwardRef,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { RelyingParty, RequestError } from 'halo-infinite-api';
import NextLink from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import NProgress from 'nprogress';
import {
  ComponentPropsWithoutRef,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FaChartLine, FaSignOutAlt, FaTable } from 'react-icons/fa';
import { FaRankingStar } from 'react-icons/fa6';
import { useEventListener } from 'usehooks-ts';
import ErrorModal from '../components/error-modal';
import InteractionRequiredModal from '../components/interaction-required-modal';
import { Loading } from '../components/loading';
import { NavigationMenu } from '../components/navigation-menu';
import PlayerSearch from '../components/player-search/player-search';
import PrivacyWarningModal from '../components/privacy-warning-modal';
import { VerticalCenter } from '../components/vertical-center';
import { appInsights } from '../lib/application-insights/client';
import '../lib/client-polyfills';
import { getUserGamertag } from '../lib/clients';
import {
  useCurrentUser,
  useCurrentUserGamertag,
} from '../lib/hooks/current-user';
import { useFocusPlayer } from '../lib/hooks/focus-player';
import {
  useHeaderSize,
  useIsHeaderMultiRow,
} from '../lib/hooks/use-header-size';
import { localStorageEvent } from '../lib/local-storage/event-based-localstorage';
import {
  clearCache,
  getAllAccounts,
  logout,
} from '../lib/msal-instances/client';

const unloadAbortController = new AbortController();
if (typeof window !== 'undefined') {
  window.addEventListener('beforeunload', () => {
    unloadAbortController.abort();
  });
}

function shouldIgnoreError(e: Error) {
  return (
    e.name === 'AbortError' ||
    (e instanceof TypeError &&
      e.message ===
        'WeakMap key undefined must be an object or an unregistered symbol' &&
      e.stack?.includes('commandsListener') &&
      e.stack?.includes('executeCommands')) ||
    (e instanceof ClientAuthError &&
      [ClientAuthErrorCodes.networkError].includes(e.errorCode))
  );
}

const LoadingMenuButton = forwardRef<
  ComponentPropsWithoutRef<typeof Loading>,
  typeof Box
>((props, ref) => {
  return (
    <Box ref={ref} h="48px">
      <Loading {...props} />
    </Box>
  );
});

const PageViewTracker = ({
  hideErrorModal,
}: {
  hideErrorModal: () => void;
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    appInsights.trackPageView();
    NProgress.done();
    hideErrorModal();
  }, [pathname, router, searchParams, hideErrorModal]);
  return <></>;
};

export function LayoutClient({ children }: { children: React.ReactNode }) {
  const { isOpen: isErrorModalVisible, onClose: hideErrorModal } =
    useDisclosure();
  const [error, setError] = useState<Error>();
  const errorHandler = useCallback(
    async (e: Error) => {
      let shouldReload = false;
      if (e instanceof RequestError && e.response.status === 401) {
        // Something has gone wrong with auth, clear the cache and force a reload
        localStorageEvent.removeItem(`xbox.userToken`);
        localStorageEvent.removeItem(`xbox.xstsTicket.${RelyingParty.Halo}`);
        localStorageEvent.removeItem(`xbox.xstsTicket.${RelyingParty.Xbox}`);
        localStorageEvent.removeItem(`halo.authToken`);
        await clearCache();
        shouldReload = true;
      } else if (
        e.name === 'ChunkLoadError' ||
        e.message.startsWith('ChunkLoadError:')
      ) {
        shouldReload = true;
      }

      if (shouldReload) {
        // Prevent infinite reload loop
        const searchParams = new URLSearchParams(location.search);
        if (!searchParams.has('force-reload')) {
          searchParams.set('force-reload', '1');
          location.search = searchParams.toString();
          return;
        }
      }

      if (shouldIgnoreError(e)) {
        return;
      }
      setError(e);
    },
    [setError]
  );
  useEventListener(
    'unhandledrejection',
    (e) => {
      if (e.reason instanceof Error) {
        errorHandler(e.reason);
      }
    },
    undefined,
    { signal: unloadAbortController.signal }
  );
  useEventListener(
    'error',
    (e) => {
      if (e.error instanceof Error) {
        errorHandler(e.error);
      }
    },
    undefined,
    { signal: unloadAbortController.signal }
  );

  const [userIsLoading, setUserIsLoading] = useState<boolean>(false);
  useEffect(() => {
    getAllAccounts()
      .then(({ length }) => {
        if (length > 0) {
          // Load current user into cache
          setUserIsLoading(true);
          getUserGamertag();
        }
      })
      .catch(async (e) => {
        if (e instanceof ServerError) {
          await clearCache();
          window.dispatchEvent(
            new CustomEvent('interaction-required', { detail: e })
          );
          return new Promise<string>(() => {}); // Page is about to reload
        }
        throw e;
      });
  }, []);
  const gamertag = useCurrentUserGamertag();
  useEffect(() => {
    setUserIsLoading(false);
    if (gamertag) {
      appInsights.setAuthenticatedUserContext(gamertag);
    } else {
      appInsights.clearAuthenticatedUserContext();
    }
  }, [gamertag]);

  const { focusPlayer } = useFocusPlayer();
  const currentUser = useCurrentUser();

  const splitHeaderRow = useIsHeaderMultiRow();
  const headerSize = useHeaderSize();
  const logoFilename = useBreakpointValue(
    { base: '/Logomark_Cyan.svg', sm: '/Horizontal_Logo_Cyan.svg' },
    { fallback: 'md' }
  );

  const [gamerpicLoaded, setGamerpicLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (currentUser?.gamerpic.small) {
      const img = document.createElement('img');
      img.src = currentUser.gamerpic.small;
      img.onload = () => {
        setGamerpicLoaded(true);
      };
    }
  }, [currentUser]);

  return (
    <Box>
      <Suspense>
        <PageViewTracker hideErrorModal={hideErrorModal} />
      </Suspense>
      <PrivacyWarningModal />
      <InteractionRequiredModal />
      <ErrorModal isOpen={isErrorModalVisible} error={error} />
      <Card position="fixed" width="100%" zIndex={1000} p={2}>
        <Flex justifyContent="center">
          <Flex gap={2} flexWrap={'wrap'} maxW="1000px" flexGrow={1}>
            <NavigationMenu />
            <VerticalCenter cursor="pointer" flexShrink={1}>
              <Link as={NextLink} href="/">
                <Image src={logoFilename} alt="Title Logo" height="32px" />
              </Link>
            </VerticalCenter>
            {splitHeaderRow && <Spacer order={1} />}
            <VerticalCenter
              order={2}
              flexGrow={1}
              width={splitHeaderRow ? '100%' : undefined}
            >
              <PlayerSearch defaultValue={focusPlayer || undefined} />
            </VerticalCenter>
            <Flex flexDir="column" order={splitHeaderRow ? 1 : 3}>
              <Spacer />
              <Box h="48px">
                {gamertag == null ? (
                  userIsLoading ? (
                    <Loading />
                  ) : (
                    <Avatar
                      size="md"
                      cursor="pointer"
                      onClick={async () => {
                        const { length } = await getAllAccounts();
                        if (length === 0) {
                          window.dispatchEvent(
                            new CustomEvent('interaction-required', {
                              detail: true,
                            })
                          );
                        } else {
                          setUserIsLoading(true);
                          getUserGamertag();
                        }
                      }}
                    />
                  )
                ) : (
                  <Menu autoSelect={false}>
                    <MenuButton>
                      {gamerpicLoaded ? (
                        <Avatar
                          src={currentUser?.gamerpic.small}
                          backgroundColor="transparent"
                          size="md"
                        />
                      ) : (
                        <LoadingMenuButton />
                      )}
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        as={NextLink}
                        href={'/matches?gamertag=' + gamertag}
                        icon={<Icon as={FaTable} />}
                      >
                        Matches
                      </MenuItem>
                      <MenuItem
                        as={NextLink}
                        href={'/players/' + gamertag}
                        icon={<Icon as={FaChartLine} />}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem
                        as={NextLink}
                        href={'/leaderboard?gamertag=' + gamertag}
                        icon={<Icon as={FaRankingStar} />}
                      >
                        Leaderboard
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        onClick={() => {
                          NProgress.start();
                          return logout();
                        }}
                        icon={<Icon as={FaSignOutAlt} />}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </Box>
              <Spacer />
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <Box pt={`${headerSize}px`}>{children}</Box>
    </Box>
  );
}

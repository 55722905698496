'use client';
import {
  BrowserCacheLocation,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { scopes } from './scopes';
import { AuthError, LogLevel, ServerError } from '@azure/msal-node';
import { appInsights } from '../application-insights/client';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { fetcher } from '../clients/fetcher';
import requestRetryPolicy from '../match-query/request-policy';
declare global {
  interface WindowEventMap {
    'interaction-required': CustomEvent;
  }
}

function getHeaderDict(headers: Headers): Record<string, string> {
  const headerDict: Record<string, string> = {};
  headers.forEach((value: string, key: string) => {
    headerDict[key] = value;
  });
  return headerDict;
}

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env['NEXT_PUBLIC_CLIENT_ID'] ?? '',
    authority: 'https://login.live.com',
    knownAuthorities: ['login.live.com'],
    protocolMode: 'OIDC',
    redirectUri: `${
      typeof window !== 'undefined' ? window.location.origin : ''
    }/oauth2/callback`,
    OIDCOptions: {
      defaultScopes: scopes,
    },
    postLogoutRedirectUri: `${
      typeof window !== 'undefined' ? window.location.origin : ''
    }/oauth2/logout`,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      piiLoggingEnabled: false,
      logLevel: LogLevel.Error,
      loggerCallback: (level, message, containsPii) => {
        let severityLevel: SeverityLevel;
        switch (level) {
          case LogLevel.Error:
            severityLevel = SeverityLevel.Error;
            break;
          case LogLevel.Warning:
            severityLevel = SeverityLevel.Warning;
            break;
          case LogLevel.Info:
            severityLevel = SeverityLevel.Information;
            break;
          case LogLevel.Verbose:
            severityLevel = SeverityLevel.Verbose;
            break;
          default:
            severityLevel = SeverityLevel.Information;
        }
        if (!containsPii) {
          appInsights.trackTrace({ severityLevel, message });
        }
      },
    },
    networkClient: {
      async sendGetRequestAsync(url, options) {
        const response = await requestRetryPolicy.execute(() =>
          fetcher(url, { ...options, method: 'GET' })
        );
        return {
          headers: getHeaderDict(response.headers),
          body: await response.json(),
          status: response.status,
        };
      },
      async sendPostRequestAsync(url, options) {
        const response = await requestRetryPolicy.execute(() =>
          fetcher(url, { ...options, method: 'POST' })
        );
        return {
          headers: getHeaderDict(response.headers),
          body: await response.json(),
          status: response.status,
        };
      },
    },
  },
});
const initializePromise = msalInstance
  .initialize()
  .then(() => msalInstance.handleRedirectPromise());

export const initialize = () => initializePromise;

export async function acquireTokenSilent(): Promise<string> {
  try {
    const maybeToken = await initializePromise;
    if (maybeToken?.accessToken) {
      return maybeToken?.accessToken;
    }
  } catch (e) {
    if (e instanceof ServerError) {
      await msalInstance.clearCache();
      window.dispatchEvent(
        new CustomEvent('interaction-required', { detail: e })
      );
      return new Promise<string>(() => {}); // Page is about to reload
    }
    throw e;
  }

  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      throw new InteractionRequiredAuthError('No accounts in token cache.');
    }

    const token = await msalInstance.acquireTokenSilent({
      account: accounts[0],
      scopes,
    });

    return token.accessToken;
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      window.dispatchEvent(
        new CustomEvent('interaction-required', { detail: e })
      );
      return new Promise<string>(() => {}); // Page is about to reload
    } else {
      throw e;
    }
  }
}

export async function acquireTokenRedirect() {
  appInsights.trackEvent({
    name: 'Oauth2RedirectInitiated',
  });
  try {
    await msalInstance.acquireTokenRedirect({
      scopes,
      redirectUri: `${window.location.origin}/oauth2/callback`,
      state: window.location.pathname + window.location.search,
    });
  } catch (e) {
    if (
      !(e instanceof AuthError && e.errorCode === 'interaction_in_progress')
    ) {
      throw e;
    }
  }
}

export async function getAllAccounts() {
  await initializePromise;
  return msalInstance.getAllAccounts();
}

export async function logout() {
  await initializePromise;
  await msalInstance.logoutRedirect();
}

export async function clearCache() {
  await initializePromise;
  await msalInstance.clearCache();
}

'use client';
import { AuthError, ServerError } from '@azure/msal-node';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaMicrosoft } from 'react-icons/fa';
import { useEventListener } from 'usehooks-ts';
import { appInsights } from '../lib/application-insights/client';
import {
  acquireTokenRedirect,
  getAllAccounts,
} from '../lib/msal-instances/client';

export default function InteractionRequiredModal() {
  const [authError, setAuthError] = useState<AuthError | true>();
  const isOpen = useMemo(() => !!authError, [authError]);
  const [navigating, setNavigating] = useState<boolean>(false);

  const redirect = useCallback(
    async (isNavigating: boolean) => {
      if (!isNavigating) {
        setNavigating(true);
        await acquireTokenRedirect();
      } else {
        console.warn('Interaction in progress, not redirecting...');
      }
    },
    [setNavigating]
  );

  useEventListener('interaction-required', async (event) => {
    const { length } = await getAllAccounts();
    if (length === 0) {
      setAuthError(event.detail);
    } else {
      redirect(navigating);
    }
  });

  useEffect(() => {
    if (isOpen) {
      appInsights.trackEvent({
        name: 'InteractionRequiredModalOpened',
      });
    }
  }, [isOpen]);

  const ssoButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      isCentered
      onClose={() => {}}
      initialFocusRef={ssoButtonRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Microsoft Authentication Required</ModalHeader>
        <ModalBody>
          <Box>
            In order to perform requests against halowaypoint.com&apos;s API,
            you must authenticate with your Microsoft account. You will be
            redirected to a Microsoft-controlled site to complete the
            authentication, and Halo Query will receive a short-term token the
            make requests on your behalf. The token will be persisted in your
            browser&apos;s local storage, so you should not need to
            re-authenticate on future visits.
          </Box>
          <Box mt={2}>
            If, at any time in the future, you wish to revoke the access you are
            about to grant, Microsoft provides a control panel to do so{' '}
            <Link
              as={NextLink}
              href="https://account.live.com/consent/Manage"
              isExternal
            >
              here
              <ExternalLinkIcon />
            </Link>
            .
          </Box>
          <Box mt={2}>
            {authError instanceof ServerError &&
            authError.errorCode === 'access_denied' ? (
              <b>
                Make sure you actually grant the meager permissions that the SSO
                screen asks you for, otherwise the site won&apos;t function, and
                you&apos;ll just keep seeing this screen.
              </b>
            ) : (
              <b>Your account details will never be saved on our servers.</b>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            ref={ssoButtonRef}
            leftIcon={<Icon as={FaMicrosoft} />}
            isLoading={navigating}
            onClick={() => redirect(navigating)}
          >
            Proceed to Microsoft SSO
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

'use client';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react';
import { createRef } from 'react';
import { Observable } from 'rxjs';
import { useObservable } from '../../lib/hooks/use-observable';

export default function SearchInput(props: {
  value$: Observable<string>;
  setInputValue: (value: string) => void;
}) {
  const value = useObservable(props.value$, '');
  const inputRef = createRef<HTMLInputElement>();
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon />
      </InputLeftElement>
      <Input
        ref={inputRef}
        type="text"
        name="gamertag"
        placeholder="Search for a player..."
        onChange={(e) => {
          props.setInputValue(e.target.value);
        }}
        value={value}
        autoComplete="off"
      />
      {value && (
        <InputRightElement>
          <IconButton
            variant={'ghost'}
            icon={<CloseIcon />}
            aria-label="Clear"
            onClick={() => {
              props.setInputValue('');
              inputRef.current?.focus();
            }}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
